import React from 'react'
import {Alert, Icon} from 'antd'
import Layout from '../components/layout'
import {Link} from 'gatsby'
import SEO from '../components/seo'

const About = () => (
    <Layout>
         <SEO title="About" description="Learn more about what Emaildrop is."/>
        <main>
            <div className="main-wrapper">
                <h1 className="title">About</h1>
                <div className="about">
                    <div className="contents">
                        <div className="content">
                            <h2>What is Emaildrop?</h2>
                            <div className="paragraph">
                                <p>
                                    Emaildrop is a free disposable email provider. Users can 
                                    generate email addresses using this website or alternatively 
                                    use our public <Link to="/v1">GraphQL API</Link>. Disposable email
                                    addresses are a great way to protect yourself from abuse and 
                                    spam. You'll be automatically generated a random email address
                                    when you arrive on the site for the first time. You can also
                                    use a custom username if you like. What's great about Emaildrop is 
                                    that you won't need to continiously refresh your inbox since it
                                    will update in real-time as soon as new emails associated with 
                                    you account hit our servers.
                                </p>
                                <p>
                                    You can give your Emaildrop.io address to any website or service 
                                    you don't trust. You can then view emails inside your inbox, where
                                    you can also delete them. Every email has a time to live (TTL) of one hour. 
                                    No email can be recovered after it's TTL expires.
                                </p>
                                <Alert
                                    description="None of Emaildrop's email addresses are protected by a password 
                                    so every address is public and can be used by anybody."
                                    type="info"
                                    showIcon
                                    style={{
                                        marginBottom: 10
                                    }}
                                />
                                <Alert
                                description="If you know the token of an email, you can view or share the email via 
                                the url `https://emaildrop.io/email/?token={token}`."
                                type="info"
                                showIcon
                                />
                            </div>
                        </div>
                        <div className="content">
                            <h2>Emaildrop's GraphQL API <Icon type="thunderbolt" theme="twoTone" /></h2>
                            <p>
                                Emaildrop offers a free and public GraphQL API targetted towards developers
                                that may need to create disposable email addresses programatically. Developers can 
                                perform queries to get all emails associated with a particular username or retreive a 
                                particular email. Developers can also perform mutations to delete a particular email 
                                as well as subscribe to a particular username to recieve all incoming emails in 
                                real-time! 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </Layout>
)

export default About;